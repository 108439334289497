import type { SubscriberData } from '~/components/Form/Subscribe/factory/types'
import type { LeadEndpointPayload } from '~/composables/useLeadEndpoint'
import { DataLayerEventsEnum } from '~~/types'

export function newsletterSubmit(values: Partial<SubscriberData>) {
  return useLeadEndpoint(values as LeadEndpointPayload, DataLayerEventsEnum.SrovNewletterFormDone)
}

export function leadSubmit(values: Partial<SubscriberData>, event: DataLayerEventsEnum = DataLayerEventsEnum.SrovInquiryFormSubmit) {
  return useLeadEndpoint(values as LeadEndpointPayload, event)
}

export function contactIssuerSubmit(values: Partial<SubscriberData>) {
  return useLeadEndpoint(values as LeadEndpointPayload, DataLayerEventsEnum.SrovContactIssuerSubmit)
}

export function contactForwardIssuerSubmit(values: Partial<SubscriberData>) {
  return useLeadEndpoint(values as LeadEndpointPayload, DataLayerEventsEnum.SrovForwardContactToIssuerSubmit)
}

export function notCompleteIssueSubmit(values: Partial<SubscriberData>) {
  return useLeadEndpoint(values as LeadEndpointPayload, DataLayerEventsEnum.SrovIssueMoreInfo)
}
