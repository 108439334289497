import { useCookies } from '@vueuse/integrations/useCookies'
import type { DataLayerEventsEnum } from '~~/types'

// TODO: move to ~/types
export type LeadEndpointPayload = {
  email: string
  name: string
  phone: string
  [key: string]: any
}

export async function useLeadEndpoint({ email, name, phone, ...data }: LeadEndpointPayload, source: DataLayerEventsEnum) {
  const config = useRuntimeConfig()

  const route = useRoute()

  const { getAll } = useCookies([], { doNotParse: false, autoUpdateDependencies: false })

  const cookies = usePick(
    useMerge(getAll(), route.query),
    ['a_box', 'a_cam', 'utm_id', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'ga_id', 'ga_session_id', 'source', 'ext_id'],
  )

  const originalUrl = new URL(document.location.href)
  originalUrl.search = ''
  originalUrl.hash = ''

  const res = await fetch(config.app.BONDS_LEADS_API_URL, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      email: email || undefined,
      name,
      phone,
      source,
      data: {
        ...data,
        ...cookies,
        url: originalUrl,
      },
    }),
  })

  if (!res.ok) {
    throw new Error(`Leads endpoint error ${res.status}`)
  }
}
