import { type BankAccount, validators } from '@cfgtech/helpers'
import type { AddressDetailsFieldModel, BirthNumberFieldModel } from '@cfgtech/ui'

import { useI18n } from 'vue-i18n'

export function useTranslatedValidators() {
  const { t } = useI18n()

  const isinRegex = /^[A-Z]{2}\d{10}$/

  return {
    requiredValidator: (value: string | null | undefined): boolean | string => validators.validateRequired(value) || t('common.validators.required'),
    requiredDateValidator: (value: Date | null) => validators.validateRequiredDate(value) || t('common.validators.required'),
    positiveValidtor: (value: string) => ((validators.isNumberValidator(value) && validators.minValidator(value, 0)) || t('common.validators.positiveNumber')),
    isIntegerValidator: (value: string) => (validators.isNumberValidator(value) && validators.isIntegerValidator(value)) || t('common.validators.integer'),
    percentValidator: (value: string) => ((validators.isNumberValidator(value) && validators.minValidator(value, 0) && validators.maxValidator(value, 100)) || t('common.validators.percentage')),
    maximumDecimals: (value: string, max: number) => validators.maximumDecimals(value, max) || t('common.validators.maximumDecimals', max),
    companyIdValidator: (value: string | null) => (value && validators.validateIco(value)) || t('common.validators.ico'),
    bankAccountValidator: (value: BankAccount | null) => (value && validators.bankAccountValidator(value)) || t('common.validators.bankAccount'),
    emailValidator: (value: string | null) => (value && validators.validateEmail(value)) || t('common.validators.email'),
    phoneValidator: (value: string | null) => (value && validators.validatePhone(value)) || t('common.validators.phone'),
    isinValidator: (value: string | null) => (value && isinRegex.test(value)) || t('common.validators.isin'),
    birthNumberValidator: (value: BirthNumberFieldModel) => (value && validators.validateBirthNumber(value.birthNumber)) || t('common.validators.birthNumber'),
    birthNumberRequiredValidator: (value: BirthNumberFieldModel) => (value && validators.validateBirthNumberRequired({ birthDate: value })) || t('common.validators.birthNumber'),
    foreignAddressRequiredValidator: (value: AddressDetailsFieldModel) => (value && validators.validateAddressDetailsRequired(value)) || t('common.validators.required'),
  }
}
